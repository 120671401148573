import React, { Suspense, lazy, useState, useEffect, useRef } from 'react';
import GridPattern from './GridPattern';
import AnimatedTitle from './AnimatedTitle';
import './App.css';

const IconCloud = lazy(() => import('./IconCloud'));
const Footer = lazy(() => import('./Footer'));
const Menu = lazy(() => import('./Menu'));

const App = () => {
  const sceneContainerRef = useRef(null);

  return (
    <div
      ref={sceneContainerRef}
      className="scene-container"
      style={{ height: '100vh', opacity: 1, position: 'relative' }}
    >
      {/* Descomentado el video y comentada la imagen estática */}
      <video className="video-background" autoPlay muted loop>
        <source src="/assets/background.mp4" type="video/mp4" />
        Tu navegador no soporta el video en HTML5.
      </video>
      {/* <img src="/assets/blun.webp" alt="Imagen de fondo" className="image-background" /> */}

      <GridPattern />
      <Suspense fallback={<div>Cargando...</div>}>
        <Menu />
        <AnimatedTitle />
        <IconCloud
          iconSlugs={[
            "typescript", "javascript", "dart", "java", "react", "flutter", "android", "html5", "css3",
            "nodedotjs", "express", "nextdotjs", "prisma", "amazonaws", "postgresql", "firebase",
            "nginx", "vercel", "testinglibrary", "jest", "cypress", "docker", "git", "jira",
            "github", "gitlab", "visualstudiocode", "androidstudio", "sonarqube", "deno", "figma"
          ]}
        />
        <Footer style={{ position: 'absolute', bottom: 0, width: '100%' }} />
      </Suspense>
    </div>
  );
};

export default App;
